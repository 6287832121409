import React, { useRef, useEffect } from 'react';
import { css, cx } from 'emotion';

import { GrafanaTheme, PanelProps } from '@grafana/data';
import { LinkButton, stylesFactory, useStyles } from '@grafana/ui';

import { SimpleOptions } from 'types';
import { Icon, IconTypes } from '../icons';

interface Props extends PanelProps<SimpleOptions> {}

const links = [
  {
    link: 'https://grafana.com/docs/grafana-cloud/',
    icon: IconTypes.DOCUMENTATION,
    text: 'Documentation',
  },
  {
    link: 'https://grafana.com/profile/org#support',
    icon: IconTypes.SUPPORT,
    text: 'Support',
  },
  {
    link: 'https://community.grafana.com/',
    icon: IconTypes.COMMUNITY,
    text: 'Community',
  },
  {
    link: 'https://grafana.com/docs/grafana-cloud/quickstart/',
    icon: IconTypes.QUICKSTARTS,
    text: 'Quickstarts',
  },
];

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  const img = theme.isDark ? 'homepage-bg.svg' : 'homepage-bg-light.svg';
  const imgUrl = `/public/plugins/cloud-home-links/img/${img}`;

  return {
    wrapper: css`
      align-items: center;
      background: ${theme.isDark ? 'bottom' : 'top'} left no-repeat url(${imgUrl});
      background-size: cover;
      display: flex;
      justify-content: space-between;
      height: 100%;
      padding: 0% 8px;
      width: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `,
    icon: css`
      @media (max-width: 650px) {
        display: none;
      }
    `,
    item: css`
      flex-grow: 1;
    `,
    title: css`
      color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      font-size: 15px;
      padding-left: ${theme.spacing.md};
      @media (max-width: 700px) {
        display: none;
      }
    `,
    links: css`
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      list-style: none;
      @media (max-width: 700px) {
        justify-content: center;
      }
    `,
    link: css`
      color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      font-size: ${theme.typography.size.sm};
      @media (max-width: 650px) {
        padding: 0px;
      }
    `,
    linkText: css`
      margin-left: 5px;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: 650px) {
        font-size: 10px;
      }
    `,
  };
});

export const CloudHomeLinks: React.FC<Props> = () => {
  const styles = useStyles(getStyles);
  const elementRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  // We need to unset the padding and border from the wrappers of the panel
  // so that we can follow the design and have a panel with a full background
  useEffect(() => {
    const divElement: HTMLDivElement = elementRef.current;
    const parent = divElement?.parentNode as HTMLElement | null;
    const grandParent = parent?.parentNode as HTMLElement | null;

    if (parent) {
      parent.style.padding = 'unset';
    }

    if (grandParent) {
      grandParent.style.border = 'unset';
    }
  }, []);

  return (
    <div ref={elementRef} className={cx('links-panel', styles.wrapper)}>
      <div className={cx(styles.item, styles.title)}>Quick links</div>
      <div className={styles.item}>
        <ul className={styles.links}>
          {links.map((link) => (
            <li key={link.text}>
              <LinkButton target="_blank" className={styles.link} fill="text" href={link.link}>
                <Icon className={styles.icon} logo={link.icon} />
                <span className={styles.linkText}>{link.text}</span>
              </LinkButton>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
