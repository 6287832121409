import React, { FC } from 'react';

export const Documentation: FC<{ className: string }> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.9998 1.33337H5.33317C4.62593 1.33337 3.94765 1.61433 3.44755 2.11442C2.94746 2.61452 2.6665 3.2928 2.6665 4.00004V12C2.6665 12.7073 2.94746 13.3856 3.44755 13.8857C3.94765 14.3858 4.62593 14.6667 5.33317 14.6667H11.9998C12.3535 14.6667 12.6926 14.5262 12.9426 14.2762C13.1927 14.0261 13.3332 13.687 13.3332 13.3334V2.66671C13.3332 2.31309 13.1927 1.97395 12.9426 1.7239C12.6926 1.47385 12.3535 1.33337 11.9998 1.33337ZM3.99984 4.00004C3.99984 3.64642 4.14031 3.30728 4.39036 3.05723C4.64041 2.80718 4.97955 2.66671 5.33317 2.66671H11.9998V9.33337H5.33317C4.8632 9.33534 4.4025 9.46434 3.99984 9.70671V4.00004ZM5.33317 13.3334C4.97955 13.3334 4.64041 13.1929 4.39036 12.9429C4.14031 12.6928 3.99984 12.3537 3.99984 12C3.99984 11.6464 4.14031 11.3073 4.39036 11.0572C4.64041 10.8072 4.97955 10.6667 5.33317 10.6667H11.9998V13.3334H5.33317ZM6.6665 5.33337H9.33317C9.50998 5.33337 9.67955 5.26314 9.80457 5.13811C9.9296 5.01309 9.99984 4.84352 9.99984 4.66671C9.99984 4.4899 9.9296 4.32033 9.80457 4.1953C9.67955 4.07028 9.50998 4.00004 9.33317 4.00004H6.6665C6.48969 4.00004 6.32012 4.07028 6.1951 4.1953C6.07008 4.32033 5.99984 4.4899 5.99984 4.66671C5.99984 4.84352 6.07008 5.01309 6.1951 5.13811C6.32012 5.26314 6.48969 5.33337 6.6665 5.33337Z" />
  </svg>
);
