import React, { FC } from 'react';

export const QuickStarts: FC<{ className: string }> = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.8122 5.50613C11.7567 5.38055 11.6666 5.27277 11.552 5.19502C11.4375 5.11727 11.3031 5.07265 11.1641 5.06621H7.75195L8.6981 1.59088C8.72824 1.48213 8.73249 1.368 8.71052 1.25737C8.68856 1.14674 8.64097 1.04259 8.57146 0.953001C8.50205 0.864274 8.41292 0.79237 8.3109 0.742803C8.20888 0.693237 8.09668 0.667325 7.9829 0.667056H2.76786C2.59967 0.661386 2.43448 0.711927 2.2992 0.810448C2.16392 0.908969 2.06652 1.04966 2.02286 1.20962L0.0262458 8.54155C-0.00388807 8.6503 -0.00813739 8.76442 0.0138282 8.87505C0.0357937 8.98568 0.0833823 9.08984 0.152896 9.17943C0.223065 9.26914 0.31339 9.34164 0.416793 9.39125C0.520196 9.44086 0.633868 9.46623 0.748901 9.46537H3.63207L2.28361 14.4071C2.23943 14.5664 2.25122 14.7358 2.31706 14.8877C2.38291 15.0397 2.49896 15.1653 2.6464 15.2442C2.79384 15.3231 2.96404 15.3507 3.12942 15.3225C3.2948 15.2944 3.44566 15.2121 3.55757 15.089L11.6781 6.29065C11.7748 6.18645 11.8389 6.057 11.8627 5.91784C11.8865 5.77869 11.869 5.63573 11.8122 5.50613ZM4.51863 11.8702L5.31578 8.93747C5.34592 8.82872 5.35017 8.7146 5.3282 8.60397C5.30623 8.49334 5.25865 8.38918 5.18913 8.2996C5.11973 8.21087 5.0306 8.13896 4.92858 8.0894C4.82656 8.03983 4.71435 8.01392 4.60058 8.01365H1.73976L3.33407 2.13344H7.00694L6.06079 5.60878C6.03041 5.71953 6.02687 5.83575 6.05044 5.9481C6.07401 6.06045 6.12403 6.16578 6.1965 6.25564C6.26896 6.3455 6.36184 6.41737 6.46766 6.46547C6.57348 6.51357 6.6893 6.53656 6.80579 6.5326H9.46546L4.51863 11.8702Z" />
  </svg>
);
