import React, { FC } from 'react';

import { Changelog } from './Changelog';
import { Community } from './Community';
import { Documentation } from './Documentation';
import { QuickStarts } from './QuickStarts';
import { Support } from './Support';

import { IconTypes } from './types';

interface IconProps {
  className: string;
  logo: IconTypes;
}

export const Icon: FC<IconProps> = ({ className, logo }) => {
  switch (logo) {
    case IconTypes.CHANGELOG:
      return <Changelog className={className} />;
    case IconTypes.COMMUNITY:
      return <Community className={className} />;
    case IconTypes.DOCUMENTATION:
      return <Documentation className={className} />;
    case IconTypes.QUICKSTARTS:
      return <QuickStarts className={className} />;
    case IconTypes.SUPPORT:
      return <Support className={className} />;
    default:
      return null;
  }
};
